/**
 *
 * '||''|.                            '||
 *  ||   ||    ....  .... ...   ....   ||    ...   ... ...  ... ..
 *  ||    || .|...||  '|.  |  .|...||  ||  .|  '|.  ||'  ||  ||' ''
 *  ||    || ||        '|.|   ||       ||  ||   ||  ||    |  ||
 * .||...|'   '|...'    '|     '|...' .||.  '|..|'  ||...'  .||.
 *                                                  ||
 * --------------- By Display:inline ------------- '''' -----------
 *
 * Modal windows styles
 */

/* Root element */
#modals {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	}
	#modals.with-blocker {
        z-index: 999300;
		bottom: 0;
	}

	/* Background blocker */
	.modal-blocker {
		position: fixed;
		z-index: 89;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0);
		-webkit-transition: background-color 300ms;
		-moz-transition: background-color 300ms;
		-ms-transition: background-color 300ms;
		-o-transition: background-color 300ms;
		transition: background-color 300ms;
		}
		.modal-blocker.visible {
			background: url('../img/old-browsers/black45.png');
			background: rgba(0, 0, 0, 0.7);
		}

	/* Modal window */
	.modal {
		position: fixed;
		z-index: 89;
		left: 0;
		top: 0;
		padding: 20px;
		-webkit-background-clip: padding-box;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		border-width: 1px;
		border-style: solid;
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), inset 0 0 3px rgba(255, 255, 255, 0.25), 0 3px 9px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), inset 0 0 3px rgba(255, 255, 255, 0.25), 0 3px 9px rgba(0, 0, 0, 0.5);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), inset 0 0 3px rgba(255, 255, 255, 0.25), 0 3px 9px rgba(0, 0, 0, 0.5);
		overflow: hidden;
		}
		.modal-bg {
			background: white;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			margin: -15px;
			padding: 10px;
			color: #666666;
		}
		.modal-content {
			overflow: hidden;
		}
		.modal-scroll {
			overflow: auto;
		}
	.modal-bar {
		height: 38px;
		line-height: 38px;
		cursor: move;
		margin: -20px -20px 20px -20px;
		padding: 0 12px;
		-webkit-background-clip: padding-box;
		-webkit-border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		-moz-border-radius: 10px 10px 0 0;
		border-radius: 10px 10px 0 0;
		-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.15);
		-moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.15);
		box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.15);
		}
		.modal-bar > h3 {
			color: white;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 13px;
			line-height: 16px;
			font-weight: bold;
			margin: 0;
			padding: 10px 0 12px;
			-webkit-text-shadow: 0 -1px 0 black;
			-moz-text-shadow: 0 -1px 0 black;
			text-shadow: 0 -1px 0 black;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.modal-actions {
			float: left;
			height: 34px;
			margin: 2px;
			list-style-type: none;
			}
			.modal > .modal-actions {
				float: none;
				position: absolute;
				z-index: 88;
				left: 8px;
				top: -4px;
			}
			.modal-actions + .modal-bg,
			.modal-actions + .modal-content {
				margin-top: 10px;
			}
			.modal-actions > li {
				display: block;
				float: left;
				width: 34px;
				height: 34px;
				background: url(../img/standard/sprites.png) no-repeat 0 -94px;
				margin-left: -12px;
				position: relative;
				z-index: 88;
				}
				.modal-actions > li:hover {
					z-index: 90;
				}
				.modal-actions > li > a {
					display: block;
					width: 34px;
					height: 34px;
					background: url(../img/standard/sprites.png) no-repeat -34px -94px;
					overflow: hidden;
					text-indent: -5000px;
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
					filter: alpha(opacity=0);
					-khtml-opacity: 0;
					-moz-opacity: 0;
					opacity: 0;
					-webkit-transition: opacity 800ms;
					-moz-transition: opacity 800ms;
					-ms-transition: opacity 800ms;
					-o-transition: opacity 800ms;
					transition: opacity 800ms;
					}
					.modal-actions > li.green-hover > a,
					.modal-actions > li.green-light > a {
						background-position: -68px -94px;
					}
					.modal-actions > li.orange-hover > a,
					.modal-actions > li.orange-light > a {
						background-position: -102px -94px;
					}
					.modal-actions > li.red-hover > a,
					.modal-actions > li.red-light > a {
						background-position: -136px -94px;
					}
					.modal-actions > li.blue-hover > a,
					.modal-actions > li.blue-light > a {
						background-position: -170px -94px;
					}
					.modal-bar:hover li > a,
					.modal-actions > li.white-light > a,
					.modal-actions > li.green-light > a,
					.modal-actions > li.orange-light > a,
					.modal-actions > li.red-light > a,
					.modal-actions > li.blue-light > a {
						opacity: 0.65;
					}
					.modal-actions > li > a:hover {
						-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
						filter: alpha(opacity=100);
						-khtml-opacity: 1;
						-moz-opacity: 1;
						opacity: 1;
						-webkit-transition: opacity 200ms;
						-moz-transition: opacity 200ms;
						-ms-transition: opacity 200ms;
						-o-transition: opacity 200ms;
						transition: opacity 200ms;
					}

	.modal-buttons {
		margin: 0px -10px -20px -10px;
		padding: 10px 10px 10px;
		-webkit-background-clip: padding-box;
		-webkit-border-bottom-left-radius: 4px;
		-webkit-border-bottom-right-radius: 4px;
		-moz-border-radius: 0 0 4px 4px;
		border-radius: 0 0 4px 4px;
		}
		.modal-buttons.low-padding {
			padding: 10px;
		}


@media (min-width: 768px) {
    .modal-bg {
        padding: 20px;
    }

    .modal-buttons {
        margin: 0px -20px -20px -20px;
        padding: 10px 20px 10px;
    }
}