/**
 *
 * '||''|.                            '||
 *  ||   ||    ....  .... ...   ....   ||    ...   ... ...  ... ..
 *  ||    || .|...||  '|.  |  .|...||  ||  .|  '|.  ||'  ||  ||' ''
 *  ||    || ||        '|.|   ||       ||  ||   ||  ||    |  ||
 * .||...|'   '|...'    '|     '|...' .||.  '|..|'  ||...'  .||.
 *                                                  ||
 * --------------- By Display:inline ------------- '''' -----------
 *
 * Agenda styles
 */

/* Events list */
.events {
    list-style-type: none;
    margin-left: 0px;
    margin-bottom: 0px;
    color: #a0a0a0;
}

.events > li {
    border-top: 1px solid #e6e6e6;
    position: relative;
    padding: 17px 20px 1px 80px;
    min-height: 44px;
}

.subscription .events > li {
    padding:0 20px 0 0;
    height:90px;
    clear:both;
    width:100%;
    text-align:left;
}

.events > li:first-child {
    border-top: 0;
}

.event-title {
    max-width: 220px;
    min-width: 220px;
    float:left;
    line-height: 90px;
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    color: #999999;
    text-align: left;
    border-right: 1px solid #e6e6e6;
    line-height:90px;
    height:90px;
}

.subscription .event-description {
    height:90px;
    line-height: 90px;
}

.subscription .event-description h1 {
    margin:0;
    padding:0;
    line-height: 90px;
}
.event-date {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 55px;
    line-height: 80px;
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    color: #999999;
    text-align: center;
    border-right: 1px solid #e6e6e6;
}

.with-month {
    line-height: 66px;
}

.event-month {
    display: block;
    font-size: 10px;
    line-height: 10px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: #bfbfbf;
    margin-top: -12px;
}

.event-description {
    display: block;
    padding-top: 4px;
    padding-left: 30px;
    min-height: 0px;
}

.subscription .event-description {
    float:left;
    padding-left: 30px;
}

a.event-description {
    color: #a0a0a0;
}

a.event-description:hover {
    background-color: #f0f5fa;
}

.events h4 {
    color: #666666;
    margin: 0 0 8px;
}

/*
 * Daily/Weekly agenda
 */
.agenda {
    position: relative;
    height: 450px; /* Default agenda height, may be changed to your convinience */
    border-left: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
}

.block > .agenda {
    border-right: 0;
    border-left: 0;
}

/* Times */
.agenda-time {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    bottom: 16px;
    margin: 0;
    list-style-type: none;
    color: #bfbfbf;
}

.with-header > .agenda-time {
    top: 56px;
}

.agenda-time > li {
    border-top: 1px solid #e6e6e6;
    position: absolute;
    left: 0;
    right: 0;
}

.agenda-time > li.silver {
    border-top-color: #cccccc;
}

.agenda-time > li.black {
    border-top-color: black;
}

.agenda-time > li.anthracite {
    border-top-color: #4c4c4c;
}

.agenda-time > li.grey {
    border-top-color: #a5a5a5;
}

.agenda-time > li.white {
    border-top-color: white;
}

.agenda-time > li.red {
    border-top-color: #dd380d;
}

.agenda-time > li.orange {
    border-top-color: #ffae00;
}

.agenda-time > li.green {
    border-top-color: #99c624;
}

.agenda-time > li.blue {
    border-top-color: #0059a0;
}

.agenda-time > li > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 11px;
    margin: 0 0 0 3px;
}

/* Header */
.agenda.with-header {
    padding-top: 40px;
    border-bottom: 1px solid #bfbfbf;
}

.agenda-header {
    position: absolute;
    z-index: 88;
    top: 0;
    left: 0;
    right: 0;
    height: 38px;
    line-height: 38px;
    font-size: 18px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    text-align: center;
    -webkit-text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    -moz-text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), 0 1px 3px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), 0 1px 3px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), 0 1px 3px rgba(0, 0, 0, 0.15);
}

.agenda-events > .agenda-header {
    top: -56px;
}

.agenda-wrapper > .agenda-events .agenda-header {
    border-left-width: 1px;
}

.agenda-wrapper > .agenda-events:first-child .agenda-header,
.scrolling-agenda .agenda-visible-first .agenda-header {
    border-left-width: 0;
}

/* Scroll arrows */
.agenda-previous,
.agenda-next {
    display: block;
    position: absolute;
    width: 38px;
    height: 38px;
    text-align: center;
    top: 50%;
    margin-top: -18px;
}

a.agenda-previous,
a.agenda-next {
    color: inherit;
/ / color: expression(this . parentNode . currentStyle [ 'color' ]);
}

span.agenda-previous, .agenda-previous.disabled,
span.agenda-next, .agenda-next.disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -khtml-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
}

.agenda-previous > .icon:before,
.agenda-next > .icon:before {
    font-size: 40px;
}

.agenda-previous {
    left: 0;
}

.agenda-next {
    right: 0;
}

/* When plugin scrolling is enabled */
.scrolling-agenda .agenda-previous,
.scrolling-agenda .agenda-next {
    display: none;
}

.agenda-visible-first .agenda-previous {
    display: block;
}

.agenda-visible-last .agenda-next {
    display: block;
}

/* Events column */
.agenda-events {
    margin-left: 0;
    list-style-type: none;
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    bottom: 16px;
}

.with-header > .agenda-events,
.with-header > .agenda-wrapper > .agenda-events {
    top: 56px;
}

.agenda-wrapper > .agenda-events {
    border-left: 1px solid #bfbfbf;
}

.agenda-wrapper > .agenda-events:first-child {
    border-left: 0;
}

.agenda-event {
    display: block;
    position: absolute;
    left: 10px;
    right: 10px;
    font-size: 12px;
    line-height: 12px;
    padding: 3px 4px;
    border-width: 1px;
    border-style: solid;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), 0 1px 3px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), 0 1px 3px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), 0 1px 3px rgba(0, 0, 0, 0.15);
}

.agenda-event.black-gradient,
.agenda-event.anthracite-gradient,
.agenda-event.grey-gradient,
.agenda-event.blue-gradient,
.agenda-event.red-gradient,
.agenda-event.orange-gradient,
.agenda-event.green-gradient {
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 3px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 3px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 3px rgba(0, 0, 0, 0.25);
}

.agenda-event time {
    display: block;
    margin: -1px 0 2px;
    font-size: 11px;
    line-height: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    filter: alpha(opacity=60);
    -khtml-opacity: 0.6;
    -moz-opacity: 0.6;
    opacity: 0.6;
}

/* Multiple columns wrapper */
.agenda-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.agenda-wrapper > .agenda-events {
    position: absolute;
    width: auto;
}

.agenda-wrapper > .agenda-day1 {
    left: 0;
    right: 85.714%;
}

.agenda-wrapper > .agenda-day2 {
    left: 14.286%;
    right: 71.429%;
}

.agenda-wrapper > .agenda-day3 {
    left: 28.571%;
    right: 57.143%;
}

.agenda-wrapper > .agenda-day4 {
    left: 42.857%;
    right: 42.857%;
}

.agenda-wrapper > .agenda-day5 {
    left: 57.143%;
    right: 28.571%;
}

.agenda-wrapper > .agenda-day6 {
    left: 71.429%;
    right: 14.286%;
}

.agenda-wrapper > .agenda-day7 {
    left: 85.714%;
    right: 0;
}

/* Simultaneous events */
.event-1-on-2 {
    right: 50%;
    margin-right: 5px;
}

.event-2-on-2 {
    left: 50%;
    margin-left: 5px;
}

.event-1-on-3 {
    right: 67%;
    margin-right: 5px;
}

.event-2-on-3 {
    right: 33%;
    left: 33%;
    margin-left: 5px;
    margin-right: 5px;
}

.event-3-on-3 {
    left: 67%;
    margin-left: 5px;
}

/* Curent time marker */
.agenda-now {
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #dd380d;
    color: white;
}

.agenda-now > span {
    display: block;
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    background: #dd380d;
    padding: 0 5px 1px;
    top: -7px;
    right: 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

/* Classes for events spreading out of default time-range */
.from-before {
    top: -16px;
    border-top: 0;
    -webkit-border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-topright: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.to-after {
    bottom: -16px;
    border-bottom: 0;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/*
 * Times
 *
 * The default time range goes from 7:00 to 20:00. To change this, do as follow:
 * - Get the number of hours in the desired range, for instance 7 -> 20 = 13
 * - Calculate the interval for each hour : 100/13 = 7.692
 * - Start at the first hour with top: 0
 * - Then increase top position for each hour by the calculated interval
 * - Half hours are set using adding half the interval
 *
 * Do the same but in reverse for the to-* values, start from 100, then decrease.
 *
 */
.from-7, .at-7 {
    top: 0%;
}

.from-7-30, .at-7-30 {
    top: 3.846%;
}

.from-8, .at-8 {
    top: 7.692%;
}

.from-8-30, .at-8-30 {
    top: 11.538%;
}

.from-9, .at-9 {
    top: 15.385%;
}

.from-9-30, .at-9-30 {
    top: 19.231%;
}

.from-10, .at-10 {
    top: 23.077%;
}

.from-10-30, .at-10-30 {
    top: 26.923%;
}

.from-11, .at-11 {
    top: 30.769%;
}

.from-11-30, .at-11-30 {
    top: 34.615%;
}

.from-12, .at-12 {
    top: 38.462%;
}

.from-12-30, .at-12-30 {
    top: 42.308%;
}

.from-13, .at-13 {
    top: 46.154%;
}

.from-13-30, .at-13-30 {
    top: 50%;
}

.from-14, .at-14 {
    top: 53.846%;
}

.from-14-30, .at-14-30 {
    top: 57.692%;
}

.from-15, .at-15 {
    top: 61.538%;
}

.from-15-30, .at-15-30 {
    top: 65.385%;
}

.from-16, .at-16 {
    top: 69.231%;
}

.from-16-30, .at-16-30 {
    top: 73.077%;
}

.from-17, .at-17 {
    top: 76.923%;
}

.from-17-30, .at-17-30 {
    top: 80.769%;
}

.from-18, .at-18 {
    top: 84.615%;
}

.from-18-30, .at-18-30 {
    top: 88.462%;
}

.from-19, .at-19 {
    top: 92.308%;
}

.from-19-30, .at-19-30 {
    top: 96.154%;
}

.from-20, .at-20 {
    top: 100%;
}

.to-7 {
    bottom: 100%;
}

.to-7-30 {
    bottom: 96.154%;
}

.to-8 {
    bottom: 92.308%;
}

.to-8-30 {
    bottom: 88.462%;
}

.to-9 {
    bottom: 84.615%;
}

.to-9-30 {
    bottom: 80.769%;
}

.to-10 {
    bottom: 76.923%;
}

.to-10-30 {
    bottom: 73.077%;
}

.to-11 {
    bottom: 69.231%;
}

.to-11-30 {
    bottom: 65.385%;
}

.to-12 {
    bottom: 61.538%;
}

.to-12-30 {
    bottom: 57.692%;
}

.to-13 {
    bottom: 53.846%;
}

.to-13-30 {
    bottom: 50%;
}

.to-14 {
    bottom: 46.154%;
}

.to-14-30 {
    bottom: 42.308%;
}

.to-15 {
    bottom: 38.462%;
}

.to-15-30 {
    bottom: 34.615%;
}

.to-16 {
    bottom: 30.769%;
}

.to-16-30 {
    bottom: 26.923%;
}

.to-17 {
    bottom: 23.077%;
}

.to-17-30 {
    bottom: 19.231%;
}

.to-18 {
    bottom: 15.385%;
}

.to-18-30 {
    bottom: 11.538%;
}

.to-19 {
    bottom: 7.692%;
}

.to-19-30 {
    bottom: 3.846%;
}

.to-20 {
    bottom: 0%;
}

/*480px __________________________________________________________________________________________________________ */
@media only screen and (min-width: 480px) {

    /*
     * Daily/Weekly agenda
     */
    .agenda {
        margin-left: 60px;
    }

    .block > .agenda {
        border-left: 1px solid #bfbfbf;
    }

    .agenda-time > li > span {
        font-size: 13px;
        left: auto;
        right: 100%;
        margin: -8px 10px 0 0;
        width: 50px;
        text-align: right;
    }

}

/*/mediaquery*/

@media (min-width: 768px) {
    .event-date {
        width: 80px;
        font-size: 48px;
    }

    .events > li {
        padding: 17px 20px 1px 100px;
    }

    .event-description {
        padding-top: 0px;
        min-height: 44px;
    }
}