
table.dataTable {
	background: white;
	color: #666666;
}
table.dataTable td.center,
table.dataTable td.dataTables_empty {
	text-align: center;
}

table.dataTable tr.even { background-color: #f7f7f7; }
table.dataTable tr.even:hover { background: #f0f0f0; }

table.dataTable tr.odd td.sorting_1 { background-color: #f1f1f1; }
table.dataTable tr.odd td.sorting_2 { background-color: #f6f6f6; }
table.dataTable tr.odd td.sorting_3 { background-color: #fbfbfb; }
table.dataTable tr.even td.sorting_1 { background-color: #e7e7e7; }
table.dataTable tr.even td.sorting_2 { background-color: #eaeaea; }
table.dataTable tr.even td.sorting_3 { background-color: #ededed; }


/*
 * Table wrapper
 */
.dataTables_wrapper,
.dataTables_header,
.dataTables_footer {
	color: white;
	background: #00AEEF url(../../../img/old-browsers/colors/bg_button-icon.png) repeat-x;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(#8DD8F8), to(#00AEEF));
	background: -webkit-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -moz-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -ms-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -o-linear-gradient(top, #8DD8F8, #00AEEF);
	background: linear-gradient(top, #8DD8F8, #00AEEF);
	}
	.dataTables_wrapper {
		position: relative;
		clear: both;
		*zoom: 1;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 0 1px #00BAFF, inset 0 2px 0 rgba(255, 255, 255, 0.35);
		-moz-box-shadow: inset 0 0 0 1px #00BAFF, inset 0 2px 0 rgba(255, 255, 255, 0.35);
		box-shadow: inset 0 0 0 1px #00BAFF, inset 0 2px 0 rgba(255, 255, 255, 0.35);
	}
	.dataTables_header {
		border: 1px solid #00BAFF;
		-webkit-border-top-left-radius: 10px;
		-webkit-border-top-right-radius: 10px;
		-moz-border-radius: 10px 10px 0 0;
		border-radius: 10px 10px 0 0;
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.35);
		-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.35);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.35);
	}
	.dataTables_footer {
		border: 1px solid #00BAFF;
		-webkit-border-bottom-left-radius: 10px;
		-webkit-border-bottom-right-radius: 10px;
		-moz-border-radius: 0 0 10px 10px;
		border-radius: 0 0 10px 10px;
	}

.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_paginate {
	padding: 10px;
	line-height: 30px;
}


/*
 * Page length menu
 */
.dataTables_length {
	float: left;
}


/*
 * Filter
 */
.dataTables_filter {
	float: right;
	text-align: right;
	}
	.dataTables_filter input {
		display: inline-block;
		text-align: left;
		padding: 7px 9px;
		line-height: 16px;
		border: 0;
		margin-left: 5px;
		vertical-align: baseline;
		-webkit-background-clip: padding-box;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		background: #e6e6e6 url(../../../img/old-browsers/colors/bg_input.png) repeat-x;
		-webkit-background-size: 100% 100%;
		-moz-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		background-size: 100% 100%;
		background: -webkit-gradient(linear, left top, left bottom, from(white), to(#e6e6e6));
		background: -webkit-linear-gradient(top, white, #e6e6e6);
		background: -moz-linear-gradient(top, white, #e6e6e6);
		background: -ms-linear-gradient(top, white, #e6e6e6);
		background: -o-linear-gradient(top, white, #e6e6e6);
		background: linear-gradient(top, white, #e6e6e6);
		border-color: #cccccc;
		color: #666666;
		-webkit-box-shadow: inset 0 0 0 1px rgba(51, 153, 255, 0), inset 0 2px 5px rgba(0, 0, 0, 0.35), 0 1px 1px rgba(255, 255, 255, 0.5), 0 0 0 rgba(51, 153, 255, 0);
		-moz-box-shadow: inset 0 0 0 1px rgba(51, 153, 255, 0), inset 0 2px 5px rgba(0, 0, 0, 0.35), 0 1px 1px rgba(255, 255, 255, 0.5), 0 0 0 rgba(51, 153, 255, 0);
		box-shadow: inset 0 0 0 1px rgba(51, 153, 255, 0), inset 0 2px 5px rgba(0, 0, 0, 0.35), 0 1px 1px rgba(255, 255, 255, 0.5), 0 0 0 rgba(51, 153, 255, 0);
		}
		.ie7 .dataTables_filter input {
			display: inline;
			zoom: 1;
			vertical-align: middle;
		}
		.no-boxshadow .dataTables_filter input {
			border-width: 1px;
			border-style: solid;
			padding-top: 6px;
			padding-bottom: 6px;
			min-height: 16px;
		}


/*
 * Table information
 */
.dataTables_info {
	clear: both;
	float: left;
}


/*
 * Pagination
 */
.dataTables_paginate {
	float: right;
	text-align: right;
}

/* Button style */
.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next,
.paging_full_numbers a {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: baseline;
	*vertical-align: middle;
	position: relative;
	text-align: center;
	font-weight: bold;
	text-transform: none;
	padding: 0 11px;
	font-size: 13px;
	line-height: 28px;
	height: 28px;
	*line-height: 26px;
	*height: 26px;
	color: white;
	cursor: pointer;
	border: 1px solid #00BAFF;
	background-position: center center !important; /* This is required for glossy gradients image fallbacck*/
	background: #00AEEF url(../../../img/old-browsers/colors/bg_blue-gradient_glossy.png) repeat-x;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(#8DD8F8), to(#00AEEF), color-stop(0.5, #039BDB), color-stop(0.5, #009bd5));
	background: -webkit-linear-gradient(top, #8DD8F8, #039BDB 50%, #009bd5 50%, #00AEEF);
	background: -moz-linear-gradient(top, #8DD8F8, #039BDB 50%, #009bd5 50%, #00AEEF);
	background: -ms-linear-gradient(top, #8DD8F8, #039BDB 50%, #009bd5 50%, #00AEEF);
	background: -o-linear-gradient(top, #8DD8F8, #039BDB 50%, #009bd5 50%, #00AEEF);
	background: linear-gradient(top, #8DD8F8, #039BDB 50%, #009bd5 50%, #00AEEF);
	-webkit-background-clip: padding-box;
	-webkit-appearance: none;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.25);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.25);
	-webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
	-moz-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
	}
	.paginate_disabled_previous,
	.paginate_enabled_previous {
		-webkit-border-top-left-radius: 4px;
		-webkit-border-bottom-left-radius: 4px;
		-moz-border-radius: 4px 0 0 4px;
		border-radius: 4px 0 0 4px;
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.25);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 1px 1px rgba(0, 0, 0, 0.25);
	}
	.paginate_disabled_next,
	.paginate_enabled_next {
		border-left: 0;
		-webkit-border-top-right-radius: 4px;
		-webkit-border-bottom-right-radius: 4px;
		-moz-border-radius: 0 4px 4px 0;
		border-radius: 0 4px 4px 0;
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 1px 0 0 rgba(255, 255, 255, 0.35), 0 1px 1px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 1px 0 0 rgba(255, 255, 255, 0.35), 0 1px 1px rgba(0, 0, 0, 0.25);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 1px 0 0 rgba(255, 255, 255, 0.35), 0 1px 1px rgba(0, 0, 0, 0.25);
	}
	.paginate_enabled_previous:hover,
	.paginate_enabled_next:hover,
	.paging_full_numbers a:hover {
		color: white;
		background: #009bd6 url(../../../img/old-browsers/colors/bg_a_blue-gradient_glossy_hover.png) repeat-x;
		-webkit-background-size: 100% 100%;
		-moz-background-size: 100% 100%;
		-o-background-size: 100% 100%;
		background-size: 100% 100%;
		background: -webkit-gradient(linear, left top, left bottom, from(#75d0f7), to(#009bd6), color-stop(0.5, #0389c2), color-stop(0.5, #0088bc));
		background: -webkit-linear-gradient(top, #75d0f7, #0389c2 50%, #0088bc 50%, #009bd6);
		background: -moz-linear-gradient(top, #75d0f7, #0389c2 50%, #0088bc 50%, #009bd6);
		background: -ms-linear-gradient(top, #75d0f7, #0389c2 50%, #0088bc 50%, #009bd6);
		background: -o-linear-gradient(top, #75d0f7, #0389c2 50%, #0088bc 50%, #009bd6);
		background: linear-gradient(top, #75d0f7, #0389c2 50%, #0088bc 50%, #009bd6);
	}

/* Two button pagination - previous / next */
.paginate_disabled_previous:active,
.paginate_enabled_previous:active,
.paginate_disabled_next:active,
.paginate_enabled_next:active {
	outline: none;
}

.paginate_disabled_previous,
.paginate_disabled_next {
	color: #74a8d4 !important;
	-webkit-text-shadow: none;
	-moz-text-shadow: none;
	text-shadow: none;
}
.paginate_disabled_previous:before,
.paginate_enabled_previous:before,
.paginate_disabled_next:after,
.paginate_enabled_next:after {
	display: inline-block;
	font-weight: normal;
	font-style: normal;
	font-family: 'EntypoRegular';
	font-size: 2em;
	line-height: 1em;
	margin: -0.5em -0.077em -0.5em -0.077em;
	vertical-align: -0.077em;
	}
	.paginate_disabled_previous:before,
	.paginate_enabled_previous:before {
		content: 'Å';
		margin-right: 5px;
	}
	.paginate_disabled_next:after,
	.paginate_enabled_next:after {
		content: 'Ä';
		margin-left: 5px;
	}

/* Full number pagination */
.paging_full_numbers a.paginate_button {
	border-left-width: 0;
	}
	.paging_full_numbers a.paginate_button.first {
		border-left-width: 1px;
		-webkit-border-top-left-radius: 4px;
		-webkit-border-bottom-left-radius: 4px;
		-moz-border-radius: 4px 0 0 4px;
		border-radius: 4px 0 0 4px;
	}
	.paging_full_numbers a.paginate_button.last {
		-webkit-border-top-right-radius: 4px;
		-webkit-border-bottom-right-radius: 4px;
		-moz-border-radius: 0 4px 4px 0;
		border-radius: 0 4px 4px 0;
	}
.paging_full_numbers a.paginate_active,
.paging_full_numbers a.paginate_active.first,
.paging_full_numbers a.paginate_active.last {
	border: 0;
	padding: 1px 12px;
	background: #00AEEF url(../../../img/old-browsers/colors/bg_blue-active_active.png) repeat-x;
	background: -webkit-gradient(linear, left top, left bottom, from(#8DD8F8), to(#00AEEF));
	background: -webkit-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -moz-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -ms-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -o-linear-gradient(top, #8DD8F8, #00AEEF);
	background: linear-gradient(top, #8DD8F8, #00AEEF);
	color: white;
	-webkit-box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.75), 0 1px 1px rgba(255, 255, 255, 0.35);
	-moz-box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.75), 0 1px 1px rgba(255, 255, 255, 0.35);
	box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.75), 0 1px 1px rgba(255, 255, 255, 0.35);
	-webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
	-moz-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.6);
}


/*
 * Processing indicator
 */
.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 250px;
	height: 30px;
	margin-left: -125px;
	margin-top: -15px;
	padding: 14px 0 2px 0;
	border: 1px solid #ddd;
	text-align: center;
	color: #999;
	font-size: 14px;
	background-color: white;
}


/*
 * Sorting
 */
table.dataTable th:active {
	outline: none;
}
.sorting:hover {
	color: #00AEEF;
	cursor: pointer;
}
.sorting_asc:before,
.sorting_desc:before {
	font-size: 0.75em;
	padding-right: 2px;
	vertical-align: 0.15em;
	}
	.sorting_asc:before {
		content: '▼';
	}
	.sorting_desc:before {
		content: '▲';
	}


/*
 * Scrolling
 */
.dataTables_scroll {
	clear: both;
}

.dataTables_scrollBody {
	*margin-top: -1px;
	-webkit-overflow-scrolling: touch;
}



/* The legen - wait for it - dary clearfix */
.dataTables_wrapper:before, .dataTables_wrapper:after,
.dataTables_header:before, .dataTables_header:after,
.dataTables_footer:before, .dataTables_footer:after {  content: "\0020"; display: block; height: 0; visibility: hidden;	 }
.dataTables_wrapper:after,
.dataTables_header:after,
.dataTables_footer:after { clear: both; }
.dataTables_wrapper,
.dataTables_header,
.dataTables_footer { zoom: 1; }