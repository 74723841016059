.package-column , .package-details { -webkit-transition: all 300ms ease-in-out; -moz-transition: all 300ms ease-in-out; transition: all 300ms ease-in-out;}
.package-column { border: 1px solid #F0F0F0; background: #F7F7F7;}

.package-column:hover { background: #00AEEF; }
.package-column:hover > .package-price  { background:rgba(71, 72, 76, 0.2); }
.package-column:hover > .package-detail ul li { border-color: rgba(255,255,255,0.3); }
.package-column:hover > .package-price .price,
.package-column:hover > .package-price .period,
.package-column:hover > .package-title,
.package-column:hover > .package-title .blue,
.package-column:hover > .package-detail ul li { color: #fff !important; }

.package-column.featured > .package-detail .btn.btn-secondary, .package-column:hover > .package-detail .btn.btn-secondary  { color: #fff ; border-color:#fff !important; }

.package-detail .btn.btn-secondary:hover { color: #00AEEF !important; background: #fff !important; border-color:#fff !important; }

.package-column .package-title { color: #47484c; font-size: 20px; padding: 20px 0 18px; font-weight: 300; text-transform: uppercase; }
.package-column .header { font-size: 30px !important; }
.package-column .package-price { background:rgba(171, 172, 179, 0.1); padding: 15px; }
.package-column .package-price .price { color: #00AEEF; font-size: 110px; font-weight: 300; line-height: 60px; position: relative; margin-top: 20px; }
.package-column .package-price .currency { font-size: 47px; line-height: 47px; font-weight: 300; position: relative; top:-40px; }
.package-column .package-price .period { margin-top: 8px; font-size: 15px; color: #00AEEF; text-transform: uppercase; }
.package-column .package-detail { padding: 20px 0px 30px 0px; }
.package-column .package-detail ul { margin-bottom: 30px; list-style-type: none; }
.package-column .package-detail ul li { font-weight: 300; font-size: 16px; color: #47484c; border-bottom: 1px solid #dadfe3; padding: 15px 0; }
.package-column .package-detail ul li:last-child { border-bottom: none; }