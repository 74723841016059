.gldp-default {
	float: left;
	background-color: #F0F4F7;
	border: 0;
	-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
	-moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.45);
}

/* Table style */
.gldp-default table {
	border-spacing: 0;
	border-collapse: collapse;
	background-color: #F0F4F7;
}

/* Default row height */
.gldp-default tr {
	line-height: 30px;
}

/* Default style of cell */
.gldp-default td {
	margin: 0;
	padding: 0;
	width: 33px;
	text-align: center;
}

.gldp-default-prevnext,
.gldp-default-beforeafter,
.gldp-default-monyear {
	color: white;
	line-height: 38px;
	white-space: nowrap;
	font-weight: bold;
	background: #00AEEF url(../../../img/old-browsers/colors/bg_blue-gradient.png) repeat-x;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(#8DD8F8), to(#00AEEF));
	background: -webkit-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -moz-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -ms-linear-gradient(top, #8DD8F8, #00AEEF);
	background: -o-linear-gradient(top, #8DD8F8, #00AEEF);
	background: linear-gradient(top, #8DD8F8, #00AEEF);
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
	-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
	-webkit-text-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.65);
	-moz-text-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.65);
	text-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.65);
	}

/* Previous and Next arrows */
.gldp-default-prevnext, .gldp-default-beforeafter {
	cursor: pointer;
	font-size: 12px;
	border-top: 1px solid #00BAFF;
	border-right: 1px solid #00BAFF;
	border-bottom: 1px solid #00BAFF;
	}
	.gldp-default-prevnext:first-child, .gldp-default-beforeafter:first-child {
		border-right: 0;
		border-left: 1px solid #00BAFF;
	}

/* Month Year title */
.gldp-default-monyear {
	border-top: 1px solid #00BAFF;
	border-bottom: 1px solid #00BAFF;
	font-size: 13px;
	width: auto;
}

/* Days of the week (Sun, Mon, ...) */
tr.gldp-default-dow {
	font-size: 9px;
	line-height: 20px;
	color: #666666;
	font-weight: bold;
	text-transform: uppercase;
	background: #e4e7eb url(../../../img/old-browsers/colors/bg_white-gradient.png) repeat-x;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(#f7f7fa), to(#e4e7eb));
	background: -webkit-linear-gradient(top, #f7f7fa, #e4e7eb);
	background: -moz-linear-gradient(top, #f7f7fa, #e4e7eb);
	background: -ms-linear-gradient(top, #f7f7fa, #e4e7eb);
	background: -o-linear-gradient(top, #f7f7fa, #e4e7eb);
	background: linear-gradient(top, #f7f7fa, #e4e7eb);
	}
	tr.gldp-default-dow > td {
		padding-top: 1px;
		-webkit-box-shadow: inset 0 2px 0 #c7c9ca;
		-moz-box-shadow: inset 0 2px 0 #c7c9ca;
		box-shadow: inset 0 2px 0 #c7c9ca;
		-webkit-text-shadow: 0 1px 0 white;
		-moz-text-shadow: 0 1px 0 white;
		text-shadow: 0 1px 0 white;
	}


.gldp-days {
	border-top: 1px solid #ececec;
	border-left: 1px solid #ececec;
	font-size: 12px;
	}
	.gldp-default-dow + tr > .gldp-days {
		border-top: 1px solid #ced3d6;
	}
	.gldp-days:first-child {
		border-left: 0;
	}

/* Selectable days */
.gldp-default-day,
.gldp-default-day-hover {
	font-weight: bold;
	cursor: pointer;
	}
	.gldp-default-day {
		color: #6c7174;
	}
	.gldp-default-day-hover {
		background: #e5e5e5;
		-webkit-text-shadow: 0 1px 0 white;
		-moz-text-shadow: 0 1px 0 white;
		text-shadow: 0 1px 0 white;
	}

/* Selected days */
.gldp-default-selected {
	background-color: #e5e5e5;
	border: solid 1px #c3c7ca;
	font-weight: bold;
	-webkit-text-shadow: 0 1px 0 white;
	-moz-text-shadow: 0 1px 0 white;
	text-shadow: 0 1px 0 white;
}

/* Today */
.gldp-default-today,
.gldp-default-today-hover {
	color: #fff;
	cursor: pointer;
	font-weight: bold;
	-webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.65);
	-moz-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.65);
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.65);
	}
	.gldp-default-today {
		background-color: #00AEEF;
	}
	.gldp-default-today-hover {
		background-color: #09BCFF;
	}

/* Weekends */
.gldp-default-sat, .gldp-default-sun {
	background-color: #fff;
	color: #666;
	font-weight: bold;
}
.gldp-default-sat-hover, .gldp-default-sun-hover {
	background-color: #fff;
	color: #8DD8F8;
	cursor: pointer;
	font-weight: bold;
}

.gldp-default-noday {
	background-color: #f2f2f2;
	color: #cfd1d2;
	cursor: wait;
}