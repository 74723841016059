/*
 * HTML5 Boilerplate reset
 * @url http://html5boilerplate.com/
 */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,  footer, header, hgroup,
menu, nav, section, summary, time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display:block;
}
nav ul { list-style:none; }
blockquote, q { quotes:none; }
blockquote:before, blockquote:after,
q:before, q:after { content:''; content:none; }
a { margin:0; padding:0; font-size:100%; vertical-align:baseline; background:transparent; }
ins { background-color:#ff9; color:#000; text-decoration:none; }
mark { background-color:#ff9; color:#000; font-style:italic; font-weight:bold; }
del { text-decoration: line-through; }
abbr[title], dfn[title] { border-bottom:1px dotted; cursor:help; }
table { border-collapse:collapse; border-spacing:0; }
hr { display:block; height:1px; border:0; border-top:1px solid #ccc; margin:1em 0; padding:0; }
input, select { vertical-align:middle; }

body { font:13px/1.231 sans-serif; *font-size:small; }
select, input, textarea, button { font:99% sans-serif; }
pre, code, kbd, samp { font-family: monospace, sans-serif; }

body, select, input, textarea {   color: #444; }
h1,h2,h3,h4,h5,h6 { font-weight: bold; }
html { overflow-y: scroll; }

a:hover, a:active, a:focus { outline: none; ie-dummy: expression(this.hideFocus=true); }
a, a:active { color: #607890; }
a:hover { color: #036; }

ul, ol { margin-left: 1.8em; }
ol { list-style-type: decimal; }

nav ul, nav li { margin: 0; }
small { font-size: 85%; }
strong, th { font-weight: bold; }
td, td img { vertical-align: top; }
sub { vertical-align: sub; font-size: smaller; }
sup { vertical-align: super; font-size: smaller; }
pre {  padding: 15px;  white-space: pre;  white-space: pre-wrap;  white-space: pre-line;  word-wrap: break-word; }
textarea { overflow: auto; }
.ie6 legend, .ie7 legend { margin-left: -7px; }
input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; }
.ie7 input[type="checkbox"] { vertical-align: baseline; }
.ie6 input { vertical-align: text-bottom; }
label, input[type=button], input[type=submit], button { cursor: pointer; }
button, input, select, textarea { margin: 0; }
input:valid, textarea:valid   {  }
input:invalid, textarea:invalid {  }
.no-boxshadow input:invalid,
.no-boxshadow textarea:invalid { background-color: #f0dddd; }

button {  width: auto; overflow: visible; }