/**
 *
 * '||''|.                            '||
 *  ||   ||    ....  .... ...   ....   ||    ...   ... ...  ... ..
 *  ||    || .|...||  '|.  |  .|...||  ||  .|  '|.  ||'  ||  ||' ''
 *  ||    || ||        '|.|   ||       ||  ||   ||  ||    |  ||
 * .||...|'   '|...'    '|     '|...' .||.  '|..|'  ||...'  .||.
 *                                                  ||
 * --------------- By Display:inline ------------- '''' -----------
 *
 * Styles for files lists
 */

/* Basic icon-style list */
.files-icons {
	list-style-type: none;
	margin: 0 0 10px -10px;
	text-align: center;
	}
	.files-icons > li {
		float: left;
		width: 85px;
		height: 32px;
		padding: 76px 0 0;
		margin: 0 0 10px 10px;
		position: relative;
		color: #FFFFFF;
		text-overflow: ellipsis;
		word-wrap: break-word;
		}
		.files-icons > li > .file-link {
			display: block;
			margin: -76px 0 0;
			padding: 76px 0 0;
			height: 32px;
			color: #FFFFFF;
			text-overflow: ellipsis;
			word-wrap: break-word;
		}
		.files-icons > li > a {
			color: #FFFFFF;
			}
			.files-icons > li > .file-link:hover,
			.files-icons > li > a:hover {
				color: #00AEEF;
			}
		.files-icons.on-dark > li,
		.files-icons.on-dark > li > .file-link,
		.files-icons.on-dark > li > a {
			color: white;
			}
			.files-icons.on-dark > li > .file-link:hover,
			.files-icons.on-dark > li > a:hover {
				color: #cccccc;
			}
		.files-icons > li > .icon,
		.files-icons > li > .file-link > .icon {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 70px;
			line-height: 66px;
			background-position: center center;
			background-repeat: no-repeat;
			}
			.files-icons > li > .icon > img,
			.files-icons > li > .file-link > .icon > img {
				vertical-align: middle;
				margin: 0;
				max-width: 64px;
				max-height: 64px;
			}
			.framed {
				border: 3px solid white;
				-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.65), 0 2px 5px rgba(0, 0, 0, 0.25);
				-moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.65), 0 2px 5px rgba(0, 0, 0, 0.25);
				box-shadow: 0 0 1px rgba(0, 0, 0, 0.65), 0 2px 5px rgba(0, 0, 0, 0.25);
				-webkit-border-radius: 2px;
				-moz-border-radius: 2px;
				border-radius: 2px;
				}
				.no-boxshadow .framed {
					border-color: #cfcfcf;
				}

		/* Hover controls */
		.files-icons > li > .controls {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			right: 0;
			height: 70px;
			line-height: 70px;
			text-align: center;
			background: url(../img/old-browsers/black45.png);
			background: rgba(0, 0, 0, 0.45);
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
			filter: alpha(opacity=0);
			-khtml-opacity: 0;
			-moz-opacity: 0;
			opacity: 0;
			-webkit-transition: opacity 200ms;
			-moz-transition: opacity 200ms;
			-ms-transition: opacity 200ms;
			-o-transition: opacity 200ms;
			transition: opacity 200ms;
			}
			.files-icons > li:hover > .controls {
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
				filter: alpha(opacity=100);
				-khtml-opacity: 1;
				-moz-opacity: 1;
				opacity: 1;
			}
			.touch .files-icons > li > .controls {
				top: auto;
				bottom: 100%;
				margin-bottom: -6px;
				height: auto;
				padding: 4px 0;
				line-height: 30px;
				-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
				-moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
				}
				.touch .files-icons > li > .controls:after {
					display: block;
					position: absolute;
					content: '';
					left: 50%;
					top: 100%;
					width: 0;
					height: 0;
					border: 4px solid transparent;
					margin-left: -4px;
					border-top-color: rgba(0, 0, 0, 0.45);
				}

	/* Mini size */
	.files-icons.mini {
		margin: 0 0 14px -6px;
		}
		.files-icons.mini > li {
			width: 50px;
			height: 24px;
			padding: 40px 0 0;
			margin: 0 0 6px 6px;
			font-size: 9px;
			text-transform: uppercase;
			line-height: 12px;
			}
			.files-icons.mini > li > .file-link {
				margin: -40px 0 0;
				padding: 40px 0 0;
				height: 24px;
				}
				.files-icons.mini > li > .icon,
				.files-icons.mini > li > .file-link > .icon {
					height: 36px;
					line-height: 32px;
					background-position: center center;
					background-repeat: no-repeat;
					}
					.files-icons.mini .framed {
						border-width: 2px;
					}
				.files-icons.mini > li > .icon > img,
				.files-icons.mini > li > .file-link > .icon > img {
					max-width: 32px;
					max-height: 32px;
				}
			.files-icons.mini > li > .controls {
				height: 36px;
				line-height: 36px;
			}

/* List style */
.files-list {
	list-style-type: none;
	margin-left: 0;
	}
	.files-list > li {
		padding: 24px 10px 24px 80px;
		min-height: 32px;
		position: relative;
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.78), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
		-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.78), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.78), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
		}
		.no-boxshadow .files-list > li + li {
			border-top: 1px solid #f0f0f0;
		}
		.files-list > li > .file-link {
			margin: -24px -10px -24px -80px;
			padding: 24px 10px 24px 80px;
			display: block;
			min-height: 32px;
			white-space: nowrap;
			text-overflow: ellipsis;
			word-wrap: break-word;
		}
		.files-list > li > a {
			color: #808080;
			}
			.files-list > li > .file-link:hover,
			.files-list > li > a:hover {
				color: #0099ff;
			}
		.files-list.on-dark > li,
		.files-list.on-dark > li > .file-link,
		.files-list.on-dark > li > a {
			color: white;
			}
			.files-list.on-dark > li > .file-link:hover,
			.files-list.on-dark > li > a:hover {
				color: #cccccc;
			}
		.files-list > li > .icon,
		.files-list > li > .file-link > .icon {
			position: absolute;
			top: 0;
			left: 8px;
			bottom: 0;
			width: 64px;
			line-height: 76px;
			background-position: center center;
			background-repeat: no-repeat;
			text-align: center;
			}
			.files-list > li > .icon > img,
			.files-list > li > .file-link > .icon > img {
				vertical-align: middle;
				margin: 0;
				max-width: 64px;
				max-height: 64px;
			}

		/* Hover controls */
		.files-list > li > .controls {
			position: absolute;
			display: block;
			top: 0;
			right: 12px;
			bottom: 0;
			line-height: 76px;
		}

	/* Mini style */
	.files-list.mini > li {
		padding: 16px 10px 16px 48px;
		min-height: 16px;
		}
		.files-list.mini > li > .file-link {
			margin: -16px -10px -16px -45px;
			padding: 16px 10px 16px 45px;
			min-height: 16px;
		}
		.files-list.mini > li > .icon,
		.files-list.mini > li > .file-link > .icon {
			left: 8px;
			width: 32px;
			line-height: 44px;
			}
			.files-list.mini > li > .icon > img,
			.files-list.mini > li > .file-link > .icon > img {
				max-width: 32px;
				max-height: 32px;
			}
		.files-list.mini > li > .controls {
			line-height: 44px;
		}

/* File types */
.file-bmp			{ background-image: url(../img/fileicons/bmp.png); }
.file-default		{ background-image: url(../img/fileicons/default.png); }
.file-excel			{ background-image: url(../img/fileicons/excel.png); }
.file-xls			{ background-image: url(../img/fileicons/excel.png); }
.file-xlsx			{ background-image: url(../img/fileicons/excel.png); }
.file-gif			{ background-image: url(../img/fileicons/gif.png); }
.file-html			{ background-image: url(../img/fileicons/html.png); }
.file-image			{ background-image: url(../img/fileicons/other_image.png); }
.file-tif			{ background-image: url(../img/fileicons/other_image.png); }
.file-tiff			{ background-image: url(../img/fileicons/other_image.png); }
.file-jpeg			{ background-image: url(../img/fileicons/jpg.png); }
.file-jpg			{ background-image: url(../img/fileicons/jpg.png); }
.file-pdf			{ background-image: url(../img/fileicons/pdf.png); }
.file-png			{ background-image: url(../img/fileicons/png.png); }
.file-rtf			{ background-image: url(../img/fileicons/rtf.png); }
.file-txt			{ background-image: url(../img/fileicons/text.png); }
.file-word			{ background-image: url(../img/fileicons/word.png); }
.file-doc			{ background-image: url(../img/fileicons/word.png); }
.file-docx			{ background-image: url(../img/fileicons/word.png); }
.file-loading		{ background-image: url(../img/fileicons/loading.gif); }

/* Gallery */
.gallery {
	list-style-type: none;
	margin-left: 0;
	}
	.gallery > li {
		float: left;
		position: relative;
		width: 10em;
		height: 10em;
		line-height: 10em;
		text-align: center;
		}
		.gallery > li > a {
			display: block;
			width: 10em;
			height: 10em;
			line-height: 10em;
		}
		.gallery > li > img,
		.gallery > li > .stack > img,
		.gallery > li > a > img,
		.gallery > li > a > .stack > img {
			margin: 0;
			vertical-align: middle;
			max-width: 8em;
			max-height: 8em;
		}
		.gallery > li > .controls {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 10em;
			height: 10em;
			line-height: 10em;
			background: url(../img/old-browsers/black45.png);
			background: rgba(0, 0, 0, 0.45);
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
			filter: alpha(opacity=0);
			-khtml-opacity: 0;
			-moz-opacity: 0;
			opacity: 0;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			-webkit-transition: opacity 200ms;
			-moz-transition: opacity 200ms;
			-ms-transition: opacity 200ms;
			-o-transition: opacity 200ms;
			transition: opacity 200ms;
			}
			.gallery > li:hover > .controls {
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
				filter: alpha(opacity=100);
				-khtml-opacity: 1;
				-moz-opacity: 1;
				opacity: 1;
			}
			.gallery > li > .controls > * {
				font-size: 13px;
			}
			.touch .gallery > li > .controls {
				top: auto;
				bottom: 75%;
				height: auto;
				padding: 4px 0;
				line-height: 30px;
				-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
				-moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
				}
				.touch .gallery > li > .controls:after {
					display: block;
					position: absolute;
					content: '';
					left: 50%;
					top: 100%;
					width: 0;
					height: 0;
					border: 4px solid transparent;
					margin-left: -4px;
					border-top-color: rgba(0, 0, 0, 0.45);
				}

/* Stack styles */
/* From http://inspectelement.com/demos/css3/stacks/ */
.stack {
	display: inline-block;
	*display: inline;
	zoom: 1;
	position: relative;
	z-index: 10;
	vertical-align: center;
	line-height: 1px;
	}
	.stack > img {
		border: 6px solid #fff;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
		}
		.no-boxshadow .stack > img {
			border-color: #cfcfcf;
		}
	.stack:before,
	.stack:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		background: #808080;
		border: 6px solid #fff;
		left: 0;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
		-webkit-transition: 0.3s all ease-out;
		-moz-transition: 0.3s all ease-out;
		-ms-transition: 0.3s all ease-out;
		-o-transition: 0.3s all ease-out;
		transition: 0.3s all ease-out;
		}
		.stack:before {
			top: 4px;
			z-index: -10;
		}
		.stack:after {
			top: 8px;
			z-index: -20;
		}

	/* Rotated to the right from the bottom left */
	.stack.rotated:before {
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		-webkit-transform: rotate(2deg);
		-moz-transform: rotate(2deg);
		-ms-transform: rotate(2deg);
		-o-transform: rotate(2deg);
		transform: rotate(2deg);
	}
	.stack.rotated:after {
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		-webkit-transform: rotate(4deg);
		-moz-transform: rotate(4deg);
		-ms-transform: rotate(4deg);
		-o-transform: rotate(4deg);
		transform: rotate(4deg);
	}

	/* One stack element rotated in the opposite direction */
	.stack.twisted:before {
		-webkit-transform: rotate(4deg);
		-moz-transform: rotate(4deg);
		-ms-transform: rotate(4deg);
		-o-transform: rotate(4deg);
		transform: rotate(4deg);
	}
	.stack.twisted:after {
		-webkit-transform: rotate(-4deg);
		-moz-transform: rotate(-4deg);
		-ms-transform: rotate(-4deg);
		-o-transform: rotate(-4deg);
		transform: rotate(-4deg);
	}

	/* Similar to the second but rotated left */
	.stack.rotated-left:before {
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		-webkit-transform: rotate(-3deg);
		-moz-transform: rotate(-3deg);
		-ms-transform: rotate(-3deg);
		-o-transform: rotate(-3deg);
		transform: rotate(-3deg);
	}
	.stack.rotated-left:after {
		-webkit-transform-origin: bottom left;
		-moz-transform-origin: bottom left;
		-ms-transform-origin: bottom left;
		-o-transform-origin: bottom left;
		transform-origin: bottom left;
		-webkit-transform: rotate(-6deg);
		-moz-transform: rotate(-6deg);
		-ms-transform: rotate(-6deg);
		-o-transform: rotate(-6deg);
		transform: rotate(-6deg);
	}

	/* Reset all rotations on hover */
	.stack:hover:before,
	.stack:hover:after {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

/* The legen - wait for it - dary clearfix */
.files-icons:before, .files-icons:after,
.gallery:before, .gallery:after {  content: "\0020"; display: block; height: 0; visibility: hidden;	 }
.files-icons:after,
.gallery:after { clear: both; }
.files-icons,
.gallery { zoom: 1; }